.expanded-wrapper {
	
	flex-wrap: wrap;

	canvas {
		width: 100% !important;
		height: auto;
		
	}
	//Pl: Kapcsolási idő
	.icon-value-multiple {


		i{
			font-size: 30px !important;
		}

		.value-item {
			.value {
				font-size: 14px !important;
				font-weight: bold !important;
			}
		}
	}

	.chart-value {
		
		canvas {

		}
		.display{
			width: 100%;
			padding: 15px !important;

		}
		
		canvas {
			width: auto;
			height: 100%;
		}
	}


}

.doughnut-value {
	min-height: 179px;
	i {
		position: absolute;
		font-size: 30px;
		margin-top: -10px;
	}
	span {
		font-size: 16px !important;
		font-weight: bold !important;
	}
}

