/*----------------sidebar-wrapper----------------*/

.sidebar-wrapper {
  position: fixed;
  top: 100px;
  bottom: 0;
  left: 0;
  z-index: 999;

  @media screen and (max-width: 767px) {
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    height: 100% !important;
    padding-top: 70px;
  }

  .support-link {
    cursor: pointer;
    position: absolute !important;
    bottom: 0;
    width: 100%;

    @media screen and (max-width: 767px) {
      position: static !important;
    }

    @media screen and (max-height: 720px) {
      position: static !important;
    }

    div span:first-child {
      background: transparent !important;
      color: white !important;
      border: 0px solid white !important;
    }
  }

  &:after {
    /*content: "";
    width: 0;
    height: 0;
    border-left: 30.5px solid transparent;
    border-right: 30.5px solid transparent;
    border-bottom: 30.5px solid #FAFAFA;
    position: absolute;
    transform: rotate(45deg);
    top: -6px;
    right: -23px;*/
  }

  .sidebar-toggler {
    position: absolute;
    right: 0px;
    top: -4px;
    z-index: 2;
    cursor: pointer;
    transition: 0.2s transform ease-in;
  }
}

.sidebar-wrapper ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.sidebar-wrapper a {
  text-decoration: none;
}

/*----------------sidebar-content----------------*/

.sidebar-wrapper {
  background: #2e3442;
  border-radius: 0 20px 0 0;
  height: calc(100% - 95px);
  width: 60px;
  transition: 0.2s transform ease-out;
  transition-delay: 0.1s;

  @media screen and (max-width: 767px) {
    border-radius: 0;
    width: 100%;
    transform: translateX(0);
  }

  .sidebar-content {
    max-height: calc(100% - 60px);
    height: calc(100% - 60px);
    position: relative;
  }

  .sidebar-content.desktop {
    overflow-y: hidden;
  }

  .sidebar-menu {
    padding-bottom: 0;
    overflow: hidden;
    padding-top: 20px;

    @media screen and (max-width: 767px) {
      padding-top: 0;
    }
  }

  .sidebar-menu .header-menu span {
    font-weight: bold;
    font-size: 14px;
    padding: 15px 20px 5px 20px;
    display: inline-block;
  }

  .sidebar-menu ul li a {
    display: flex;
    align-items: center;
    width: 100%;
    text-decoration: none;
    position: relative;
    font-size: 13px;
    color: #ffffff;
    font-weight: 600;
    line-height: 1px;
    padding: 9px 0px 9px 10px;
    transition: 0.2s padding ease-in;

    @media screen and (max-width: 767px) {
      padding: 0px 20px;
    }

    &:hover {
      background-color: #74b4b4;
    }

    &#dashboard {
      svg {
        font-size: 1.2em;
        @media screen and (max-width: 767px) {
          font-size: 1em;
        }
      }
    }

    &:hover,
    &:focus {
      color: #ffffff;
    }

    div {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 40px;
      height: 40px;
      vertical-align: middle;
      @media screen and (max-width: 767px) {
        display: block;
        width: 100%;
        height: auto;
        text-align: left;
      }
    }

    span {
      opacity: 1;
      transition: 0.1s opacity ease-in;
      transition-delay: 0.2s;
      display: flex;
      align-items: center;
      justify-content: center;

      @media screen and (max-width: 767px) {
        display: inline-block;
      }

      & + span {
        display: none;
        @media screen and (max-width: 767px) {
          display: inline-block;
          position: relative;
          top: -1px;
          padding-left: 10px;
          font-size: 14px;
        }
      }
    }

    i {
      margin-right: 10px;
      font-size: 24px;
      line-height: 40px;
      width: 30px;
      text-align: center;
      display: inline-block;
      position: relative;
      margin-right: 0px;

      @media screen and (max-width: 767px) {
        width: 24px;
        font-size: 20px;
      }
    }
  }

  .sidebar-menu ul li #dashboard i {
    font-size: 20px;
  }

  .sidebar-menu ul li a:hover > i::before {
    display: inline-block;
    animation: swing ease-in-out 0.5s 1 alternate;
  }

  .sidebar-menu .sidebar-dropdown .sidebar-submenu ul {
    background-color: #3b3b3b;
    border-top: 5px solid #222222;
  }

  .sidebar-menu .sidebar-dropdown .sidebar-submenu li {
    padding-left: 25px;
    font-size: 13px;
    height: 32px;
    display: flex;
    align-items: center;

    &:last-child {
      border-bottom: 0px solid #333333;
    }

    i {
      color: white;
      font-size: 16px;
      width: 10px;
      display: flex;
      justify-content: center;
    }
  }

  .sidebar-menu .sidebar-dropdown .sidebar-submenu li a {
    font-size: 12px;
    font-weight: 400;
    line-height: 1px;
    &:hover,
    &:focus {
      color: #ffffff;
    }
  }

  .logo-holder {
    text-align: center;
    margin-top: 20px;
  }

  .utu-light {
    display: inline-block;
  }

  .sidebar-menu ul li a span.label,
  .sidebar-menu ul li a span.badge {
    float: right;
    margin-top: 8px;
    margin-left: 5px;
  }

  .sidebar-menu .sidebar-dropdown .sidebar-submenu li a .badge,
  .sidebar-wrapper
    .sidebar-menu
    .sidebar-dropdown
    .sidebar-submenu
    li
    a
    .label {
    float: right;
    margin-top: 0px;
  }

  .sidebar-menu .sidebar-submenu {
    display: none;
  }

  .sidebar-menu .sidebar-dropdown.active .sidebar-submenu {
    display: block;
  }

  .hover {
    width: 7px;
    height: 100%;
    background: white;
    position: absolute;
    left: -10px;
    transition: 0.2s left ease-in;
    height: 58px;
  }

  .sidebar-menu .sidebar-dropdown.active > a,
  .sidebar-menu li.active > a {
    background-color: #fafafa !important;
    border-radius: 30px 0 0 30px;
    margin-left: 10px;

    @media screen and (max-width: 767px) {
      margin-left: 0;
      border-radius: 0;
    }

    span {
      color: #2e3442;

      & + span {
        color: white;

        @media screen and (max-width: 768px) {
          color: #2e3442;
        }
      }
    }
  }

  .sidebar-menu > .main-menu > li {
    position: relative;
    margin-top: 10px;

    @media screen and (max-width: 767px) {
      text-align: center;
      margin-top: 1px;
      margin-bottom: 9px;
    }

    @media screen and (max-height: 760px) {
      margin-top: 3px;
    }

    &:hover {
      .hover {
        left: 0px;
        width: 7px;
        transition: 0.2s left ease-out, 0.2s width ease-out;
      }
    }
  }

  .sidebar-menu > .main-menu > li.active {
    .hover {
      left: 0px;
      width: 7px;
      transition: 0.2s left ease-out, 0.2s width ease-out;
      z-index: 1;
      top: 1;
    }
  }

  .sidebar-menu .sidebar-dropdown.active > a:after {
    transform: rotate(90deg);
    right: 17px;
  }

  .sidebar-header,
  .sidebar-search {
    border-top: 1px solid #3a3f48;
  }

  .sidebar-search input.search-menu,
  .sidebar-search .input-group-text {
    border-color: transparent;
    box-shadow: none;
  }

  .sidebar-header .user-info .user-role,
  .sidebar-header .user-info .user-status,
  .sidebar-search input.search-menu,
  .sidebar-search .input-group-text,
  .sidebar-brand > a,
  .sidebar-menu ul li a,
  .sidebar-footer > a {
    color: #ffffff;
  }

  &.closed {
    //width: 70px;
    @media screen and (max-width: 767px) {
      transform: translateX(-100%);
    }

    /*
	.sidebar-menu .sidebar-dropdown.active .sidebar-submenu {
    display: none !important;
  }

	.sidebar-toggler {
		transform: rotate(180deg);
		transition: 0.2s transform ease-out;
	}

	a {
		transition: 0.2s padding ease-out;
    justify-content: center;
    align-items: center;

		span {
      display: table;
      vertical-align: center;
      width: 40px;
      height: 40px;
      border-radius: 0;
      text-align: center;
      color: white;
      font-size: 23px;

		}
	}

  .logo-holder {
    .utu-light {
      width: 50px;
    }
  }

  .sidebar-submenu {
     i {
      width: 100% !important;
    }
  }

   .main-menu > {
    li:hover:not(.disabled) {
      width: 220px;
      background: #515151;
      padding-left: 70px;
      cursor:pointer;


      .hover {
        width: 80px !important;
      }
      > a {
        background: #515151;
        opacity: 1;
        width: 220px;
        span {
          opacity: 1 !important;
          display: block !important;
        }
      }

      a {
        span {
          opacity: 1 !important;
          display: block !important;
        }
      }
      .sidebar-submenu {
        display: block;
        position: absolute;
        width: 100%;

        li i {
          justify-content: flex-start;
          flex: 10%;
        }

        li a {
          flex: 90%;
          text-align: left;
        }
      }
    }
  }*/
  }

  .disabled {
    a {
      opacity: 0.3;
    }

    .hover {
      display: none;
    }
  }

  .support {
    position: absolute !important;
    bottom: 70px;
    background-color: #3e3e3e;
    width: 100%;
    transition: 0.4s all ease-out;
    cursor: pointer;
    &:hover {
      background-color: #4caff1;
      transition: 0.4s all ease-out;
    }
  }
}

.support-popup {
  overflow-y: auto;
  overflow-x: hidden;

  h4 {
    display: flex;
    align-self: center;
    font-size: 20px;
    line-height: 40px;
    margin-bottom: 0;
  }
  bottom: -1000% !important;
  transition: 0.4s bottom ease-out;
  width: 80%;
  background-color: white;
  height: 90%;
  position: fixed !important;
  top: auto;
  left: 50%;
  margin-left: -40%;
  z-index: 20000;
  border: 1px solid lightgrey;

  @media screen and (max-width: 1024px) {
    width: 90%;
    margin-left: -45%;
  }

  .card-header {
    display: flex;
    align-items: center;
    justify-content: right;
    width: 100%;
    text-align: right;
  }
  .close-button {
    display: inline-block;
    display: flex;
    float: right;
    position: absolute;
    right: 15px;
    cursor: pointer;
  }

  .setting-holder {
    padding-right: 15px !important;
    padding-bottom: 30px !important;
    padding-top: 30px !important;
  }

  .card {
    border: 0px solid white !important;
  }

  .card-body {
    padding: 0px;
  }

  .infos {
    display: flex;
    width: 100%;
    flex-flow: column;
    align-items: stretch;
    height: 100%;
    .info-row {
      display: flex;
      width: 100%;
      flex-direction: row;
    }

    .info-row.full {
      flex: 1;
      .key {
        align-items: flex-start;
      }

      .form-group {
        .col-md-6 {
          @media screen and (max-width: 768px) {
            margin-bottom: 15px;
          }
        }
      }
    }

    .action {
      .value {
        flex-flow: row;
        align-items: center;
        span {
          margin-left: 20px;
        }
      }
    }
  }

  .key {
    padding: 30px;
    display: flex;
    font-size: 12px;
    font-weight: bold;
    flex: 25% 0 0;
    border: 1px solid lightgrey;
    border-left: 0px solid lightgrey;
    border-top: 0px;
    align-items: center;
  }

  .value {
    padding: 30px;
    display: flex;
    flex: 75% 0 0;
    flex-direction: column;
    padding-left: 30px;
    border: 1px solid lightgrey;
    border-left: 0px solid lightgrey;
    border-top: 0px;
    border-right: 0px;
    font-size: 13px;

    textarea {
      height: 200px;
    }
  }
}

.overlay {
  opacity: 0;

  content: "";
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  width: 100%;
  height: 100%;
  top: -100%;
  left: 0px;
  z-index: 5001;
  transition: 0.4s opacity ease-out;
  &.shown {
    opacity: 1;
    display: block;
    top: 0px;
    transition: 0.4s opacity ease-out;
  }
}

.support-popup.shown {
  bottom: -30px !important;
  transition: 0.4s bottom ease-out;
}

//left sidebar nav tooltip
.sidebar-menu {
  overflow: visible !important;

  li div {
    position: relative;
    cursor: pointer;

    > span + span {
      position: absolute;
      left: calc(100%);
      top: 50%;
      transform: translateY(-50%);
      background-color: #2e3442 !important;
      color: whitesmoke;
      font-size: 12px;
      border-radius: 20px;
      padding: 5px 15px;
      text-align: center;
      line-height: 20px;
      min-width: 80px;
      opacity: 0;
      visibility: hidden;
      white-space: nowrap;
      transition: 0.2s all ease;
      z-index: 10;
      display: block !important;
      box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.2);

      @media screen and (max-width: 768px) {
        visibility: visible;
        position: static !important;
        display: inline-block !important;
        min-width: auto;
        background-color: transparent !important;
        box-shadow: none !important;
        transform: translateY(0);
      }

      &:before {
        position: absolute;
        width: 0;
        height: 0;
        border-top: 5px solid transparent;
        border-bottom: 5px solid transparent;
        border-right: 5px solid #2e3442;
        left: -4px;
        top: 50%;
        margin-top: -5px;
        content: "";

        @media screen and (max-width: 768px) {
          display: none;
        }
      }
    }

    &:hover {
      > span {
        opacity: 1;
        visibility: visible;
      }
    }
  }
}

.layer-filter-enable-button:hover {
  color: gray;
}

.cadastre-filter-component-container {
  ::placeholder {
    font-weight: 100 !important;
  }

  border-top: 1px solid #c7c7c7;
  padding-top: 10px;
  box-shadow: none !important;

  .back-span {
    color: #4caff1;
  }

  .back-span + p {
    font-size: 14px !important;
    margin: 0;
    text-align: start;
    padding-top: 15px;
    font-weight: 600;
  }

  .back-span:hover {
    color: #2e3442;
  }

  .back {
    margin-left: 10px;
  }

  p {
    padding-top: 10px;
    font-weight: 500;
    text-align: center;
    font-size: 20px;
  }

  .cadastre-filters {
    .form-control {
      height: 33px;
    }

    .filter-search {
      padding: 6px 12px 6px 12px;
      border: 1px solid #e9e9e9;
    }

    .filter-search-container {
      display: flex;
      align-items: center;
      border: 1px solid #e9e9e9;
      border-radius: 5px;

      i {
        padding-right: 12px;
      }
    }

    > form,
    > div {
      margin-bottom: 10px;
    }

    > div:last-child {
      padding-bottom: 10px;
    }
  }
}

.tiles-container {
  .active-tile-container {
    margin-bottom: 25px;
  }
  .row {
    width: 100%;
    margin: auto;
    padding: 5px;
    display: flex;
    align-items: center;

    @media screen and (max-width: 767px) {
      flex-wrap: nowrap;
    }

    > div:first-child {
      padding: 0;
    }

    p {
      margin: 0;
    }
  }

  .row:not(.row:first-child) {
    > div:first-child {
      padding-left: 5px;
    }
  }
}

.contact-modal {
  width: 50% !important;
  max-width: none !important;

  .modal-dialog {
    max-width: none !important;
  }
}
