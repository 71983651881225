.badge-pill {
	font-size: 10px !important;
	font-weight: normal !important;
	height: 20px;
	line-height: 20px !important;
	padding-top: 0px !important;
	padding-bottom: 0px !important;
	min-width: 30px;
}

.input-warning-validation {
	text-align: center;
    color: red;
    font-size: 16px;
    padding-top: 20px;
}

.input-accept {
	text-align: center;
    font-size: 16px;
    padding-top: 20px;
}

.input-reject {
	text-align: center;
    font-size: 16px;
    padding-top: 20px;
}