.form-check {
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	text-indent: 5px;
	.form-check-label {
		font-size: 14px;
		cursor: pointer;

		input {
			position: absolute;
			opacity: 0;
			cursor: pointer;
			height: 0;
			width: 0;
		}

		.checkmark {
			position: absolute;
		  	top: 4px;
		  	left: 0;
		  	height: 17px;
		  	width: 17px;
		  	background-color: #ffffff;
		  	box-shadow: inset 0 1px 8px 0 rgba(0,0,0,0.07);
		  	border: 1px solid #e7e7e7;
		}

		.checkmark:after {
		  content: "";
		  position: absolute;
		  display: none;
		}
	}

	&:hover input ~ .checkmark {
		  border: 1px solid #d5d5d5;
	}

	input:checked ~ .checkmark:after {
	  display: block;
	}

	.checkmark:after {
		width: 9px;
		height: 9px;
		background-color: #99D7D8;
		position: absolute;
		top: 3px;
		left: 3px;


	}
}