.ranged-slider {

	.range-handle {

		width: 20px !important;
		height: 20px !important;
		top: 4px;
		outline: none !important;
		background: white !important;
		cursor: pointer;
	  transition: box-shadow 0.5s ease;
	  border: 1px solid $thumb-border !important;
	  	-webkit-box-shadow: -1px 6px 9px -2px rgba(0,0,0,0.21);
		-moz-box-shadow: -1px 6px 9px -2px rgba(0,0,0,0.21);
		box-shadow: -1px 6px 9px -2px rgba(0,0,0,0.21);
	}

	.range-track {
		background: #ced4da !important;
		border: 0px solid white !important;
	}

}