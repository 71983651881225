.modal-90w {
    min-width: 50% !important;
    width: 50%;
    display: inline-block;
    margin: 0 auto;
}

.gantt-data-table {
    height:300px !important; 
    overflow:auto !important;
    display: block;
}

.gant-popup-datepicker {
    margin: 20px 10px 20px 10px;
    align-items: center;
    text-align: center;
}
.gantt-date-header {
    border-top: red;
}
.gantt-data-container {
    margin: 20px 20px 20px 20px
}

.table-responsive{
    height:200px;  
    overflow:scroll;
  }
   thead tr:nth-child(1) th{
      background: white;
      position: sticky;
      top: 0;
      z-index: 10;
      border: white;
    }

.timeLine {
    .timeLine-side-main {
        min-width: 300px;
    }
}