$input-border-radius: 0px;
$input-border-color: #e7e7e7;
$input-font-size: 16px;
$input-line-height: 2.315;

input.form-control {
	box-shadow: inset 0 1px 8px 0 rgba(0,0,0,0.07);
	font-weight: 600;

	&.small {
		height: 33px;
		font-size: 12px;
	}

	
}

.form-group {
	&.with-icon {
		display: flex;
		input {
			display: inline-block;
			width: 90%;
			outline: none !important;
		}

		i {
			display: flex;
			align-items: center;
			justify-content: center;
			width: 33px !important;
			height: 33px;
			border: 1px solid $input-border-color;
			font-size: 14px;
		}
	}
}

.shadow {
    padding:5px;
    border:0;
    box-shadow:0 0 15px 4px rgba(0,0,0,0.06);
    max-width: 500px;
	min-height: 20px;
	font-size: 70%;
  }

  .filter-search:focus {
	box-shadow: none;
  }

/* Removes the clear button from date inputs */
input[type="date"]::-webkit-clear-button {
    display: none;
}

/* Removes the spin button */
input[type="date"]::-webkit-inner-spin-button { 
    display: none;
}

/* Always display the drop down caret */
input[type="date"]::-webkit-calendar-picker-indicator {
    color: #2c3e50;
}

/* A few custom styles for date inputs */
input[type="date"] {
    appearance: none;
    -webkit-appearance: none;
    color: #95a5a6;
    font-family: "Helvetica", arial, sans-serif;
    font-size: 18px;
    border:1px solid #ecf0f1;
    background:#ecf0f1;
    padding:5px;
    display: inline-block !important;
    visibility: visible !important;
}

input[type="date"], focus {
    color: #95a5a6;
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
}
