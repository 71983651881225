.search {
	width: 25%;
	position: absolute;
	max-width: 240px;
	top: 15px;
	left: 15px;
	-webkit-box-shadow: 0px 2px 18px -9px rgba(0,0,0,0.63);
	-moz-box-shadow: 0px 2px 18px -9px rgba(0,0,0,0.63);
	box-shadow: 0px 2px 18px -9px rgba(0,0,0,0.63);}


.filters {
	position: absolute;
	top: 15px;
	left: 110px;
	-webkit-box-shadow: 0px 2px 18px -9px rgba(0,0,0,0.63);
	-moz-box-shadow: 0px 2px 18px -9px rgba(0,0,0,0.63);
	box-shadow: 0px 2px 18px -9px rgba(0,0,0,0.63);

	.card {
		top: 10px;
		left: -10px;
		min-width: 280px;

		.col-md-12 {
			//max-width: 90% !important;
		}
	}
}

.map-setting {
	
	-webkit-box-shadow: 0px 2px 18px -9px rgba(0,0,0,0.63);
	-moz-box-shadow: 0px 2px 18px -9px rgba(0,0,0,0.63);
	box-shadow: 0px 2px 18px -9px rgba(0,0,0,0.63);
	position: absolute;
	top: 15px;
	left: 62px;

	.card {
		min-width: 200px;
		top: 10px;
		left: -10px;

	}
}