.wifi-table {
    
    width: 97%;
    background-color: rgb(241, 241, 241);
    margin: auto;
    margin-top: 10px;
    margin-bottom: 20px;

    thead {
        text-align: center;

        th {
            padding: 10px;
            color: white;
            background:rgb(56, 56, 56) !important;
            font-weight: 500;
            font-size: 12px;
        }
    }

    tbody {
        text-align: center;
        font-weight: 400;
        font-size: 14px;
        tr {

            td {
                padding: 5px;
                border: 1px solid rgb(230, 230, 230);
            }
        }
    }

    .datasheet:hover {
        cursor: pointer;
    }

}