.map-config-sidebar {

    margin-top: 10px;
    .map-config-sidebar-header-info {
        padding: 0px 10px 0px 10px;

        div {
            margin-left: 5px;
        }
        svg {
            margin-right: 5px;
        }
    }

    .map-saved-filters-container {
        div {
            margin: 0;
        }
        .form-group {
            padding: 0 !important;
            margin: auto !important;
            width: 100% !important;
        }
        .view-filters {
            margin: 0 !important;
        }
        .select-saved-filters {
            margin: 0 !important;
        }

        .filtersavebutton {
            margin-bottom: 10px;
        }
    }

    p {
        font-size: 12px;
        margin-bottom: 5px;

        span {
            font-weight: 500;
            font-size: 13px;
        }
        .info {
            float: right;
            margin-top: 3px;
        }
    }

    .active-project {

    }

    .acitve-cadastre {

    }

    .filter-information {
        color: grey;
        padding: 10px 10px 10px 20px;
    }

    .map-config-filter-label {
        margin-top: 10px;
        font-weight: 500;
        font-size: 15px;
    }
}