.drag-n-drop-container {
    min-height: 75px;
    margin-left: 10px;
    text-align: center;
    background-color: #e3e3e3;
    color: #6b6b6b;
    border-radius: 10px;
    width: 100%;
    cursor: pointer;
    display: flex;
    align-items: center;

    p {
        padding: 5px 15px;
        width: 100%;
        margin: 0;
        
        snap{
            display: block;
            padding: 5px 0;
        }
    }
}

.selected-files {
    margin:10px;
    text-align: center;

    p {
        padding-left: 5px;
    }
}

.uploadbutton {
    margin-top: 10px;
    margin-left: 10px;
    border-radius: 10px;
    height: 40px;
    width: 100%;
    border: none;
    background-color: #59c44d;
    color:white

}

.uploadbutton:active {
    outline: none;
}

.uploadbutton:focus {
    outline: none;
}

.loader-container-upload {

    .loader {
        width: 20px !important;
        height: 20px !important;
        padding: 2px !important;
        border-top: 8px solid #6c757d !important;
    }
}

.file-upload-description-input {
    background-color: #e3e3e3;
    border-radius: 10px;
    padding: 15px;
    margin: 20px 0px 10px 25px;
    width: 100%;

    div{
        margin: 10px 0;

        span:first-child{
            display: inline-block;
            margin-bottom: 3px;
        }
    }
    
    button {
        width: 100%;
        padding: 5px;
        margin-top: 10px;
        border-radius: 10px;
        border: none;
        background-color: #59c44d;
        color:white
    }
    button:hover {
        background-color: white;
        color:#59c44d
    }
    button:disabled {
        background-color: #b1b1b1;
        color: white;
    }
    input {
        width: 100%;
        border: none;
        padding-left: 5px;

        &::placeholder{
            color: #b1b1b1;

        }
    }
}