.saved {
	// margin-top: 32px;
}

.saved-set-list {
	padding-left: 0px;
	li {
		display: flex;
		border-bottom: 1px solid #f7f7f7;
		padding-bottom: 6px;
		padding-top: 6px;
		align-items: center;
		justify-content: center;
		div {
			display: inline-block;
			flex: 1;

			&.scs {
				max-width: 40px;
				margin-right: 15px;
				img {
					width: 100%;
				}
			}

			&.name {
				font-size: 10px;
			}
			&.date {
				font-size: 11px;
				text-align: left;
			}

			&.action {
				max-width: 20px;
				text-align: right;
			}
		}
	}
}

div.card-messagebody{			
		overflow-y: auto;
		height: 230px;
		overflow-x: hidden;
}

.alert-container {
	margin-left: 60px;
	margin-top: 50px;

	.alert-list {
		max-height: 300px;
		overflow: auto;
		margin-bottom: 20px;
	}

	.mqtt-status {
		float: right;
		font-size: 12px;
		padding-left: 3px;
	}

	.alert-desc {
		font-size: 12px;
		margin-left: 22px;
	}

	.alert-date {
		font-size: 12px;
		margin-left: 22px;
	}

	p {
		margin: 0;
	}
}