.cadastre-styling-container {
  padding-bottom: 10px;
  margin-bottom: 16px;
  p {
    margin: 5px;
    margin-left: 0;
    text-align: start;
  }

  .range-slider{
    padding-top: 0 !important;
  }
}