.card {
	
    margin-bottom: 27px;

    .card-footer {
    	background: white;
	    text-align: center;
	    font-size: 14px;
	    cursor: pointer;

	    i{
	    	margin-right: 10px;
	    }

	    a {
	    	color: #444444;
	    	text-decoration: none;
	    }
    }
	.card-header {
		background-color: white;
		font-size: 15px;
		font-weight: 500;
		display: flex;
		min-height: 56px;
		color: #444444;
		

		.header-item {
			padding-top: 7.5px;
			padding-bottom: 7.5px;
			display: block;
			width: 100%;
			line-height: 20px;
			> div {
				font-size: 14px;
				font-weight: bold;
				margin-left: 30px;
			}
			small {
				font-size: 12px !important;
				font-weight: 600;
			}
		}
		
		.color {
			width: 14px;
			height: 14px;
			display: inline-block;
			margin-right: 10px;
		}

		i {
			margin-left: 10px;
			margin-right: 15px;
			font-size: 24px;
		}

	}

	
}

.status {
		width: 11px;
		height: 11px;
		border-radius: 50%;
		position: absolute;
		right: 15px;
		text-align: center;
		font-size: 9px;
		color: white;
		line-height: 11px;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		span {
			display: block;
		}
		
		&.active {
			background-color: #2CD02A;
		}
		&.no-change {
			background-color: #FFF700;
		}
		&.no-data {
			height: 28px;
			width: 116px;
			background-color: #F80000;
			border-radius: 14px;
		}
}

ul.status-list
{
	display: flex;
	flex-direction: row;
	padding: 0px;
	margin-bottom: 0px;
	li {
		list-style: none;
		display: flex;
		flex-direction: row;
		align-items: center;
		margin-right: 30px;
		font-size: 10px;

		&:last-child {
			margin-right: 0px;
		}
	}
	.status {
		position: relative;
		right: auto;
		margin-right: 10px;
		&.no-data {
			width: 11px;
			height: 11px;
			background-color: #F80000;
			border-radius: 50%;
		}
	}
}

.expanded-wrapper {
	display: flex;
	width: 100%;
}

.image-value {
	height: 225px;
	width: 100%;
	background-repeat: no-repeat;
	background-size: cover;
}

.dashboardBoxButton {
	position: relative !important;
	right: auto !important;
	margin-right: 10px !important;
}

.kataszterlist li {
	padding: 5px 40px 5px 40px;
}

.app-container{
	display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
	width: 100%;
    height: 1062px;
    max-height: 1062px;
}
