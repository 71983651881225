.map-interactions-container {
    position: absolute;
    //right: 40px;
    left: 60px;
    top: 70px;
    background-color: rgb(244, 244, 244);
    border-radius: 30px;
    z-index: 10;
    text-align: left;
    padding-right: 0;
    svg {
        margin: 8px;
    }


    .map-interactions-active {
        width: 200px;
        height: auto;
        background-color: rgb(244, 244, 244);
        border-radius: 14px;

        p {
            padding-left: 15px;
            padding-top:15px;
            margin: 0;
            font-size: 13px;
            font-weight: 200;
            font-family: "Montserrat", sans-serif;

        }

        svg {
            float: right;
            margin: 15px;
        }

        .map-interactions-active-button-container {
            padding:15px 15px 10px 15px;
        }
    }

    .map-interaction-button {
        text-align: center;
        border-radius: 5px;
        border: 0;
        box-shadow: none;
        color: white;
        font-weight: 400;
        text-shadow: none;
        font-size: 14px;
        font-family: "Montserrat", sans-serif;
        width: 100%;
        background-color: grey;
        margin-top: 10px;
        outline: none;


    }
    .active {
        background-color: #7dd173;
        color:white
    }

    .map-interaction-button:hover {
        color: grey;
        background-color: white;
    }

    .map-interaction-remove-button-container {
        text-align: center;
        outline: none;
        svg {
            margin:10px;
        }

        button {
            width: auto;
            border-radius: 5px;
            border: 0;
            box-shadow: none;
            color: white;
            font-weight: 400;
            text-shadow: none;
            font-size: 14px;
            font-family: "Montserrat", sans-serif;
            margin-top: 10px;
            font-size: 12px;
            margin: 5px;
            margin-bottom: 20px;
        }

        .remove {
            background-color: #d17373;
        }
        .remove:hover {
            color: #d17373;
            background-color: white;
        }

        .end {
            background-color: #e0dc67;
        }
        .end:hover {
            color: #e0dc67;
            background-color: white;
        }

        .history {
            background-color: #b6b6b6;

        }

        .history:hover {
            color: #b6b6b6;
            background-color: white;
        }
        .history:disabled {
            background-color: #e9e9e9;
        }
    }
}

.notextended:hover {
    background-color: #e3e3e3;
}

.map-interactions-info-block {
    position: absolute;
    left: 60px;
    top: 430px;
    background-color: rgb(244, 244, 244);
    border-radius: 20px;
    z-index: 10;
    text-align: left;
    padding-right: 0;
    max-width: 200px;
    padding: 3px;

    div {
        padding: 10px;

        svg {
            float: right;
        }
    }

    p {
        margin: 0;
        font-size: 12px;
        font-weight: 200;
        font-family: "Montserrat", sans-serif;
        color: grey;
    }

    .remove-warning {
        > button:first-child { color: red; }
        padding: 0;
        text-align: center;
        p {
            margin-bottom: 10px;
        }
        button {
            width: auto;
            border-radius: 5px;
            border: 0;
            box-shadow: none;
            color: white;
            font-weight: 400;
            text-shadow: none;
            font-size: 14px;
            font-family: "Montserrat", sans-serif;
            font-size: 12px;
            margin: 5px;
            padding:10px 10px 10px 10px
        }
        .yes {
            background-color: #d17373;
        }

        .no {
            background-color: #7dd173;

        }
    }

}

.map-interactions-edit-block {
    position: absolute;
    left: 270px;
    top: 100px;
    background-color: rgb(244, 244, 244);
    border-radius: 20px;
    z-index: 10;
    text-align: left;
    padding-right: 0;
    padding: 13px;

    svg {
        float:right
    }

    .style-container {
        p {
            font-size: 13px;
            margin: 10px 0px 10px 0px;
        }
        
        .font-picker-container {
            min-width: 200px;

            div {
                min-width: 200px;
            }
        }

        .font-size-picker-container {
            margin-top: 10px;
            p {
                display: inline-block;
                margin-right: 30px;
            }
            select {
                border: none;
                width: 80px;
            }
        }

        .font-color-picker-container {

            .circle-picker {
                max-width: 200px;
            }
        }
    }

    .default-edit-feedback {
        font-size: 12px;
    }
}