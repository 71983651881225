.search {
	display: inline-block;
	height: 67px;
	padding: 18px;
	
}

.map-button {
	background: white;
	border: 1px solid rgba(0, 0, 0, 0.125);
	cursor: pointer;

	width: 40px !important;
    height: 42px !important;
	padding: 10px;
	border-radius: 4px !important;

	.card {
		margin-top: 35px !important;
	}

	> .clickable {
		position: absolute;
	    top: 0px;
	    left: 0px;
	    width: 100%;
	    height: 100%;
	    display: flex;
	    justify-content: center;
	    align-items: center;
	}
	
	form {
		position: relative;
		display: flex;
		margin-top: 10px;
		background: white;
		padding: 10px;
		left: -10px;
		min-width: 220px;
		.form-group {
			display: flex;
			margin-bottom: 0px !important;

			input {
				min-width: 200px;
			}
		}
	}

	&.closed {
		
		overflow: hidden;

	}

}