.scroller {
  padding-right: 20px;
}

.detail {
  display: flex;
  width: 100%;
  height: calc(100vh - 260px);
  z-index: 1;
  max-width: 500px;
  position: absolute !important;
  border-radius: 0px !important;
  bottom: auto;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
  transition: 0.1s all ease-in;
  left: auto;
  transform: translateX(150%);

  @media screen and (max-width: 639px) {
    max-width: 440px;
    right: auto;
    left: 50%;
    transform: translateX(-150%);
  }

  @media screen and (max-width: 479px) {
    max-width: 320px;
  }

  @media screen and (max-width: 374px) {
    max-width: 280px;
  }

  .close {
    cursor: pointer;
  }

  .card-header {
    box-shadow: 0px 4px 7px 0 rgba(0, 0, 0, 0.1);
  }

  .card-body {
    margin-right: -15px;
  }

  &.displayed {
    transform: translateX(0);
    @media screen and (max-width: 639px) {
      transform: translateX(-50%);
    }
  }

  .map-detail-items {
    display: flex;
    flex-direction: column;
    height: 100%;

    .tab-content {
      overflow: auto;
    }


    .map-detail-item {
      background-color: #fafafa;
      padding: 15px;
      padding-top: 0px;
      margin: 15px;
      margin-right: 15px;
      margin-left: 0px;

      .info {
        display: flex;
        flex-direction: row;
        justify-content: stretch;
        border-bottom: 1px solid #d8d8d8;
        margin-left: -15px;
        margin-right: -15px;

        > div {
          padding: 15px;
          flex: 1;
          font-size: 11px;
          line-height: 17px;
          border-right: 1px solid #d8d8d8;
          > span {
            font-weight: 700;
            margin-right: 15px;
            font-size: 12px;
          }

          &:last-child {
            text-align: right;
            border-right: 0px solid #d8d8d8;
          }
        }
      }

      .item-info {
        display: flex;
        flex-direction: row;
        padding-top: 15px;
        .left {
          flex: 1;
        }

        .right {
          flex: 2;

          ul {
            list-style: none;
            display: flex;
            flex-direction: column;
            padding-left: 15px;

            li {
              display: flex;
              justify-content: space-between;
              font-size: 11px;
              line-height: 14px;
              padding-top: 12px;
              padding-bottom: 12px;
              border-bottom: 1px solid #d8d8d8;

              span {
                font-weight: 600;
              }
            }
          }
        }
      }
    }
  }
}

.mapview {
  &.full {
    .detail {
      @media screen and (max-width: 639px) {
        transform: translateX(-50%);
      }
    }
  }

  .detail {
    &.minimized {
      transition: none;
      height: auto;
    }
  }
}

.info {
  display: flex;
  flex-direction: row;
  .img {
    padding: 15px;
    flex: 1;
  }
}

.details-info {
  padding: 10px;
  flex: 2;
  .info-item {
    list-style: none;
    font-size: 12px;
    line-height: 24px;
    span {
      font-weight: bold;
      min-width: 130px;
      display: inline-block;
    }
  }
}

.filter-separator {
  width: auto;
  margin-bottom: 0;
  text-align: left;
  padding-bottom: 10px;
  svg {
    width: 10px !important;
  }
}

.filter-separator-date {
  width: auto;
  margin-bottom: 0;
  text-align: left;
  padding-bottom: 10px;
  margin-left: 25px;
  svg {
    width: 10px !important;
  }
  a {
    padding-right: 5px;
  }
}

.filter-separator-text {
  margin-left: 18px;
  font-weight: 500;
}
.filter-separator-button {
  float: left;
  margin-left: 18px;
}

.img-container {
  width: 80%;
  float: right;
  margin-left: 20%;
}

.device-details {
  font-size: 21px;
  text-align: center;
  animation: fadein 2s;
  -moz-animation: fadein 2s; /* Firefox */
  -webkit-animation: fadein 2s; /* Safari and Chrome */
  -o-animation: fadein 2s; /* Opera */
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-moz-keyframes fadein {
  /* Firefox */
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-webkit-keyframes fadein {
  /* Safari and Chrome */
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-o-keyframes fadein {
  /* Opera */
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.filter-date-pick {
  height: 30px;
  font-weight: 300;
  font-size: 17px;
  width: 100%;
  background-color: #fff !important;
  background-clip: padding-box !important;
  border: 1px solid #e7e7e7 !important;
}

.table-wrapper {
  column-count: 1;
  margin: 10px 10px 10px 10px;
  table {
    width: 100%;
    border: 0;
    background: none;
    tr {
      background-color: transparent;
      &:nth-child(2n + 1) {
        td {
          background-color: #f1f1f1;
        }
      }
      &:nth-child(2n) {
        td {
          background-color: #eaeaea;
        }
      }
      td {
        vertical-align: middle;
        background: none;
        padding: 5px 10px;
        border: 0;
        font-size: 12px;
        font-weight: 500;
        color: #555;
        text-transform: none;

        & + td {
          font-weight: 400;
          color: $baseColor;
        }
      }
    }
  }
  button {
    margin-bottom: 10px;
    background-color: Transparent;
    background-repeat: no-repeat;
    border: none;
    cursor: pointer;
    overflow: hidden;
    outline: none;
    font-size: 13px;
    border: 0.5px solid #ededed;
  }
  button:hover {
    background-color: #ededed;
  }
}

.images {
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 10px;
  .img-grid {
    .img {
      width: 50px;
      height: 50px;
      background-position: center;
      background-size: cover;
      float: left;
      margin: 0 10px 10px 0;
      cursor: pointer;
    }
  }
}

.map-details-edit-button {
  padding: 10px 20px 10px 20px;
  margin: 10px;
}

.cadastre-label-map-details {
  margin: 0;
  padding: 5px;
  background-color: whitesmoke;
  border-radius: 10px;
}

.nav-tabs {
  
  a {
    font-size: 12px;
    color: grey;
  }
}
