.comment-container {
    width: 100%;
    max-height: 300px;
    overflow: auto;
}

.loader {
    padding:10px;
    border: 8px solid #f3f3f3;
    border-top: 8px solid #6c757d;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 2s linear infinite;
  }

.loader-container {
    margin: auto;
    padding: 50px;
}
  
@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}
