.version-modal {
  width: 90%;
  max-width: none !important;

  li {
    font-size: 12px;
    margin: 2px;
  }

  .modal-body {
    height: 800px;
    overflow-y: auto;
    overflow-x: hidden;
  }
}

.detailed-nav-tabs {
  position: absolute;
  height: auto;
  background-color: white;
  border: 1px solid #f1f1f1;
  z-index: 100;
  box-shadow: 0 21px 27px 5px rgba(0, 0, 0, 0.1),
    0 11px 17px 4px rgba(0, 0, 0, 0.03);
  padding: 25px 20px 20px 20px;
  width: 300px;
  top: 55px;

  @media screen and (max-width: 569px) {
    left: 0;
    width: auto;
    right: 0;
  }

  > input {
    border: 0;
    border: 1px solid #e1e1e1;
    padding: 8px 10px;
    width: 100%;
    margin-bottom: 20px;
    color: #999;
  }

  .nav-submenu {
    div {
      padding: 0 !important;
      text-align: left;
      padding: 0;
      height: auto !important;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      & + div {
        margin-top: 15px !important;
        a {
          color: #666 !important;
          transition: 200ms all ease 0s;

          &:hover {
            color: #4caeef !important;
          }
        }
      }

      &.active {
        font-weight: normal;
        color: #4caeef !important;
        border: 0 !important;

        a {
          color: #4caeef !important;
        }
      }
    }
  }
}

.new-project-modal {
  width: 70% !important;
  max-width: none !important;
  margin: 1.75rem auto !important;
}

.new-poi-modal {
  width: 40% !important;
  max-width: none !important;
  margin: 1.75rem auto !important;

  .new-poi-modal-body {
    .row {
      padding-top: 10px;
      padding-bottom: 10px;
    }
  }

  .new-poi-type-container {
    border-left: 1px solid #f0f0f0;
  }

  .option-preview {
    width: 100%;
  }

  .warning {
    margin: auto;
    text-align: center;
    padding: 10px;
    color: red;
  }
}

.expanded {
  width: 70% !important;
}

.new-contact-modal {
  .description {
    margin-top: 52px;

    @media screen and (max-width: 991px) {
      margin-top: 0;
    }
  }

  .modal-title h3 {
    margin-bottom: 0;
  }

  .form-text {
    margin-bottom: 10px;
    font-size: 90%;
  }

  .form-group {
    margin-bottom: 30px;

    .dashboard-input-modal {
      font-size: 80%;
    }
  }

  .custom-file-label {
    font-size: 90%;
    height: 51px;
    display: flex;
    align-items: center;

    &::after {
      font-size: 1rem;
    }
  }

  .request-send-button {
    margin-top: 30px;
  }
}

.confirm.img-modal {
  .modal-body {
    padding: 100% 0 0;

    img {
      margin-top: -100%;
      padding: 0 15px;
    }
  }
}
