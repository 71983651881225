.btn-group {
	width: 100%;

	.btn {
		background-color: #efefef;
		border-color: #efefef;
		color: #757575;
		font-weight: bold;
		
	}

	.btn.selected, .btn:hover, .btn:focus, .btn:active, .btn-secondary:not(:disabled):not(.disabled).active {
		background-color: #ffffff !important;
		border-color: #efefef !important;
		color: #222222 !important;
		outline-color: transparent !important;
		box-shadow: none !important;
	}

	.btn:first-child {
		border-top-left-radius: 30px;
		border-bottom-left-radius: 30px;
	}

	.btn:last-child {
		border-top-right-radius: 30px;
		border-bottom-right-radius: 30px;
	}
}


.filter-date-btn-group {
	height: 30px;
	width: 90%;
	margin-bottom: 15px;
	button {
		font-weight: 600 !important;
		font-size: 11px !important;
		color:red;
		line-height: 10px !important; 
	}
}