.col-xl-4 {
	.widget-container {
		min-height: 493px;
	}
}

.widget-container {
	
	margin-bottom: 30px;
	min-height: 430px;
	.card-body {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		padding: 0px;

		.widget {
			display: flex;
			flex: 0 100%;
			flex-direction: column;
			border: 1px solid #d8d8d8;
			border-top: 0px solid transparent;
			span {
					font-size: 10px;
			}

			&.full {
				border: 0px solid transparent;
				border-bottom: 1px solid #d8d8d8;
			}
			&.half {
				flex: 0 50%;	
			}
			&.third {
				flex: 0 33.333333%;	
			}
			&.fourth {
				flex: 0 25%;	
			}

			&.threeoffourth {
				flex: 0 75%;
			}
			&.half:nth-child(1n) {
				border-left: 0px solid #d8d8d8;
			}
			&.half.last {
				border-right: 0px solid #d8d8d8;
			}

			&.full:last-child {
				border-bottom: 0px solid #d8d8d8;
			}

			&.third:nth-child(1n) {
				border-left: 0px solid #d8d8d8;
			}
			&.third:last-child {
				border-right: 0px solid #d8d8d8;
			}

			&.fourth:nth-child(1n) {
				border-left: 0px solid #d8d8d8;
			}

			&.fourth.last {
				border-right: 0px solid #d8d8d8;
			}
			

			.display {
				padding: 10px;
				display: flex;
				flex-direction: row;
				i {
					font-size: 28px;
					margin-right: 10px;
				}
				.value {
					font-size: 19px;
					font-weight: bold;
				}
			}



		}
	}
}

.box-item {
	order: 2;

	.normal-widget-content {
		display: flex;
	    flex-direction: row;
	    flex-wrap: wrap;
	    padding: 0px;
	}

	.expanded-content {
		display: none;
	}
}

.box-item.expanded {
	width: 100%;
	flex: 100%;
	max-width: 100%;
	max-height: 80%;
	order: 1;

	.normal-widget-content {
		display: none !important;
	}

	.expanded-content {
		
		display: flex;
	    flex-direction: row;
	    flex-wrap: wrap;
	    padding: 0px;
	    width: 100%;
	    height: 100%;
	}
}

.chart-value {
	padding-top: 10px;
	.display {
		padding: 0px !important;
		padding-top:5px !important;
		padding-bottom: 5px !important;

		.canvas-container {
		  height: 10vh;
		}
	}
}

.icon-value {
	padding-bottom: 5px;
}

.icon-value-multiple {

	/*
	@media (min-width: 1200px) and ( max-width: 1320px) {
		
		> span {
			margin-top: 5px;
		}
		.display {
			text-align: center !important;
			flex-direction: column !important;
			justify-content: center;
			width: 100%;
			padding: 0px !important;
			.value-item {
				padding: 5px;
				width: 100%;
				margin-right: 0px;
				justify-content: space-around;
				border-bottom: 1px solid #d8d8d8;
				i {
					flex: 1;
				}

				.value{
					flex: 2;
				}

			}
			
		}
	}

	@media (min-width: 769px) and ( max-width: 819px) {
		
		> span {
			margin-top: 5px;
		}
		.display {
			text-align: center !important;
			flex-direction: column !important;
			justify-content: center;
			width: 100%;
			padding: 0px !important;
			.value-item {
				padding: 5px;
				width: 100%;
				margin-right: 0px;
				justify-content: space-around;
				border-bottom: 1px solid #d8d8d8;
				i {
					flex: 1;
				}

				.value{
					flex: 2;
				}

			}
			
		}
	}

	*/
}

.divider {
	padding-top: 10px;
    padding-bottom: 10px;
}

//IconMultipleValue
.value-item {
	display: flex;
	flex-direction: row;
	align-items: center;
	margin-left: 0px;
	margin-right: 10px;
	i {
		margin-right: 5px !important;
	}
	.value {
		font-size: 11px !important;
		font-weight: 500 !important;
	}
}

//SimpleValueUnit
.simple-value-unit {
	.display {
		display: flex;
		flex-direction: column !important;
		width: 100%;

		span {
			margin-bottom: 7px !important;
		}

		.value {
			font-size: 12px !important;
			margin-bottom: 3px;
		}

		.unit {
			width: 100%;
			text-align: right !important;
			font-weight: bold;
			font-size: 12px;
		}
	}
}

//VericalProgress
.vertical-progress {
	min-height: 86px;
	position: relative;

	.progress {
		width: 5.5px;
		position: absolute;
		bottom: 5px;
		right: 5px;
		border-radius: 0px;
	}

	.display {
		flex-direction: column !important;
		justify-content: center;
		text-align: center;
	}

	span {
		font-size: 10px !important; 
		font-weight: 500; 
	}

	.value {
		font-size: 12px !important;
		font-weight: bold;
	}

	.unit {
		font-size: 15px !important;
		font-weight: bold;
		display: block;
		width: 100%;
	}
}

//HorizontalProgress 
.horizontal-progress {
	position: relative;
	display: flex;
	i {
		font-size: 15px !important;
		color: #737373 !important;
	}

	.display {
		width: 100%;
	}

	.value-container, .label-container {
		align-items: center;
		flex: 1;
	}
	.value-container {
		justify-content: right;
		font-size: 16px;
		font-weight: 500;

		div {
			text-align: right;
		}
	}

	.label-container {
		justify-content: left;
	    flex: 1;
	    display: flex;
	}
	.progress {
		height: 5.5px;
		position: absolute;
		bottom: 5px;
		left: 5px;
		border-radius: 0px;
	}
}

.double-icon {
	.icon {
		color: #959595 !important;
	}
	.icon-right {
		margin-left: 10px;
	}
}