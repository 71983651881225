.rdt_TableCell div:first-child, .rdt_TableCell div:first-child {
	    white-space: pre-wrap !important;
    overflow: unset !important;
    text-overflow: unset !important;
    text-align: left;
    line-height: 24px;
    padding-top: 4px;
    padding-bottom: 4px;
}

.infotable-wrapper {
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	justify-content: flex-start !important; 
	padding-left: 15px;
	padding-right: 15px;
}

.infotable {
	width: 100%;
	margin: 20px;
	font-size: 12px;
	text-align: left;
	vertical-align: top;
	td > tr > td {
		
		border: 1px solid #d7d7d7;
		padding-left: 10px;
		padding-right: 10px;
		padding-top: 5px;
		padding-bottom: 5px;
		font-size: 13px;
		line-height: 16px;
	}
	.key {
		font-weight: bold;
		text-transform: uppercase;
		width: 30px;
	}

	h5 {
		margin-bottom: 30px;
		font-size: 16px;
		font-weight: bold;
	}

	a {
		font-size: 16px;
		margin-bottom: 20px;
		display: block;
		i{
			font-size: 30px;
			line-height: 0px;
			margin-right: 10px;
			top: 4px;
			position: relative;
		}

		i.small {
			font-size: 15px;
			margin-left: 10px;
			position: relative;
			top: 0px;

		}
	}
}