$btn-font-size:               14px;
$btn-line-height:             2.315;
$btn-border-radius:           0px;
$btn-border-radius-lg:        0px;
$btn-border-radius-sm:        0px;

.btn.btn-primary, .btn.btn-primary:hover {
	color: #ffffff;
	font-weight: bold;
	font-size: 14px;
}

.ganttbutton {
	background-color: inherit;
	border: 0em;
}

.button_kataszter {
	-moz-box-shadow:inset 0px 1px 0px 0px #ffffff;
	-webkit-box-shadow:inset 0px 1px 0px 0px #ffffff;
	box-shadow:inset 0px 1px 0px 0px #ffffff;
	background:-webkit-gradient( linear, left top, left bottom, color-stop(0.05, #f9f9f9), color-stop(1, #e9e9e9) );
	background:-moz-linear-gradient( center top, #f9f9f9 5%, #e9e9e9 100% );
	filter:progid:DXImageTransform.Microsoft.gradient(startColorstr='#f9f9f9', endColorstr='#e9e9e9');
	background-color:#f9f9f9;
	-webkit-border-top-left-radius:6px;
	-moz-border-radius-topleft:6px;
	border-top-left-radius:6px;
	-webkit-border-top-right-radius:6px;
	-moz-border-radius-topright:6px;
	border-top-right-radius:6px;
	-webkit-border-bottom-right-radius:6px;
	-moz-border-radius-bottomright:6px;
	border-bottom-right-radius:6px;
	-webkit-border-bottom-left-radius:6px;
	-moz-border-radius-bottomleft:6px;
	border-bottom-left-radius:6px;
	text-indent:0;
	border:1px solid #dcdcdc;
	display:inline-block;
	color:#666666;
	font-family:arial;
	font-size:15px;
	font-weight:bold;
	font-style:normal;
	height:26px;
	line-height:26px;
	width:100px;
	text-decoration:none;
	text-align:center;
	text-shadow:0px 1px 0px #ffffff;
	float:right;
}

.headers {
	position: absolute;
	width: 200px;
	height: 24px;
	left: 20px;
	top: 35px;

	font-family: Montserrat;
	font-style: normal;
	font-weight: bold;
	font-size: 20px;
	line-height: 24px;
	text-transform: uppercase;

	color: #444444;
}

.kataszterHeader {
	font-family: Montserrat;
	font-style: normal;
	font-weight: bold;
	font-size: 20px;
	line-height: 24px;
	text-transform: uppercase;
	color: #444444;
}

.dashboardheader {
	font-family: Montserrat;
	font-style: normal;
	font-weight: bold;
	font-size: 20px;
	line-height: 24px;
	text-transform: uppercase;
	color: #444444;
}

.filterdeletebutton,
.filtersavebutton {
	-moz-box-shadow:inset 0px 1px 0px 0px #ffffff;
	-webkit-box-shadow:inset 0px 1px 0px 0px #ffffff;
	box-shadow:inset 0px 1px 0px 0px #ffffff;
	background:-webkit-gradient( linear, left top, left bottom, color-stop(0.05, #ffffff), color-stop(1, #f6f6f6) );
	background:-moz-linear-gradient( center top, #ffffff 5%, #f6f6f6 100% );
	filter:progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffff', endColorstr='#f6f6f6');
	background-color:#ffffff;
	-webkit-border-top-left-radius:6px;
	-moz-border-radius-topleft:6px;
	border-top-left-radius:6px;
	-webkit-border-top-right-radius:6px;
	-moz-border-radius-topright:6px;
	border-top-right-radius:6px;
	-webkit-border-bottom-right-radius:6px;
	-moz-border-radius-bottomright:6px;
	border-bottom-right-radius:6px;
	-webkit-border-bottom-left-radius:6px;
	-moz-border-radius-bottomleft:6px;
	border-bottom-left-radius:6px;
	text-indent:0;
	border:1px solid #dcdcdc;
	display:inline-block;
	color:#666666;
	font-family:arial;
	font-size:13px !important;
	font-weight:bold;
	font-style:normal;
	height:33px;
	line-height:30px;
	width:225px;
	text-decoration:none;
	text-align:center;
	text-shadow:0px 1px 0px #ffffff;
}
.filterdeletebutton:hover,
.filtersavebutton:hover {
	background:-webkit-gradient( linear, left top, left bottom, color-stop(0.05, #f6f6f6), color-stop(1, #ffffff) );
	background:-moz-linear-gradient( center top, #f6f6f6 5%, #ffffff 100% );
	filter:progid:DXImageTransform.Microsoft.gradient(startColorstr='#f6f6f6', endColorstr='#ffffff');
	background-color:#f6f6f6;
}

.filterdeletebutton:active,
.filtersavebutton:active {
	position:relative;
	top:1px;
}