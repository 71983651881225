.export-modal {
  @media screen and (max-width: 767px) {
    width: 92%;
  }

  .modal-header {
    display: flex;
    align-items: center;
  }

  .modal-title {
    h4 {
      margin-bottom: 0;
      padding: 0 15px;

      @media screen and (max-width: 767px) {
        font-size: 1rem;
      }
    }
  }

  .modal-body {
    padding-top: 24px;
    max-width: 500px;
    min-width: 500px;

    @media screen and (max-width: 767px) {
      min-width: auto;
    }

    .export-selects {
      overflow: auto;
      max-height: 400px;

      @media screen and (max-width: 375px) {
        min-width: none;
        min-height: none;
        max-width: 300px;
      }
    }
  }

  @media screen and (max-width: 375px) {
    min-width: none;
    min-height: none;
  }
}

.export-modal-checkbox {
  display: inline-block;
  text-align: right;
  width: auto;
}

.export-modal-text {
  width: 100%;
  display: inline-block;
  text-align: left;
}

.loader-container-export {
  margin: auto;
  width: 40px;
}

.export-active-container {
  margin-bottom: 10px;
  max-height: 200px;
  overflow: auto;

  .export-active-select {
    background-color: rgb(167, 167, 167);
    padding: 6px;
    border-radius: 13px;
    color: white;
    margin: 5px;
    display: inline-block;
    font-size: 12px;

    .remove-select  {
      color: white;
    }
    .remove-select:hover {
      color: rgb(71, 71, 71);
    }
  }
}

.export-selects-filter {
  input {
    width: 100%;
    margin-bottom: 10px;
    border: 1px solid rgb(194, 194, 194);
    font-size: 13px;
    padding: 5px;
    border-radius: 5px;
  }

  input:focus {
    outline: none;
    border: 1px solid rgb(145, 145, 145);
  }

  .interaction-buttons {
    text-align: center;

    @media screen and (max-width: 767px) {
      margin-bottom: 10px;
    }

    .select-button {
      display: inline-block;
      width: 49%;
      border-radius: 5px;
      margin-bottom: 20px;
      margin-top: 5px;
      margin-left: 2px;
      margin-right: 2px;
      border: none;
      background-color: rgb(199, 199, 199);
      color: black;

      @media screen and (max-width: 767px) {
        width: 100%;
        margin-bottom: 5px;
      }
    }

    .select-button:hover {
      background-color: rgb(100, 100, 100);
      color: white;
    }
  }
}

.failed-to-export {
  max-height: 50px;
  overflow: auto;
  text-align: center;
  margin-top: 10px;
  max-width: 200px;

  p {
    font-size: 12px;
    color: rgb(230, 106, 106);
  }
  span {
    font-weight: 600;
  }
}

.export-group {
  width: 100%;
}

.result-not-found-text {
  font-size: 13px;
  color: #a7a7a7;
}

.export-header {
  border-bottom: 1px solid rgb(185, 185, 185);
  padding-bottom: 5px;
}
